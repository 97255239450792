import React from 'react';
import axios from 'axios';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import {
	Button,
	Col,
	DatePicker,
	message,
	Row,
	Popover,
	Card,
	Typography,
	Avatar,
	Image,
	Progress
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateStateMortgages } from '../../../../../store/mortgages/actions';
import moment from 'moment';
import {
	WhatsAppOutlined,
	PlusCircleOutlined,
	MinusCircleOutlined,
	MailOutlined,
	ShoppingCartOutlined,
	PlusOutlined,
	HomeOutlined,
	ArrowRightOutlined
} from '@ant-design/icons';
import _ from 'lodash';
import { showAircall } from '../../../../../store/aircall/actions';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const { Text } = Typography;

const StatusWidgets = ({
	mortgage,
	owner1,
	dataMortgage,
	setRefreshNps,
	refreshNps,
	mortgageId,
	whatsappPhone,
	infoProfile,
	otherOperations,
	setVisible,
	visible,
	agent,
	getDataNotes,
	profile,
	percent1,
	setPercent1,
	mortgageUpda,
	fechaFom,
	clientPreRegister,
	setShowModalReasign,
	agentTypeName,
	whatsappPhoneInmo,
	setShowModalReu
}) => {
	const aircall = useSelector((state) => state.aircall.aircall);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleNPS = () => {
		axios
			.get(`${env.api.url}/v1/nps/first-call/send/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then(() => {
				message.success('Se envio el mail correctamente');
				setRefreshNps(!refreshNps);
			});
	};

	const callClient = (phoneNumber) => {
		aircall.send('dial_number', { phone_number: phoneNumber }, (success, data) => {
			console.log('success of dial:', success, data, aircall);
		});

		dispatch(showAircall(true));
	};

	const followUpWhatsapp = (phone) => {
		let whatsappPhone = phone;
		if (whatsappPhone && whatsappPhone.startsWith('00')) {
			whatsappPhone = `+${whatsappPhone.substring(2)}`;
		} else if (whatsappPhone && whatsappPhone.indexOf('+') === -1) {
			whatsappPhone = `+34${whatsappPhone}`;
		}
		if (whatsappPhone) {
			whatsappPhone = whatsappPhone.replace('+', '%2B');
		}

		let textWs;
		if (infoProfile && infoProfile.language === 'es') {
			textWs = `Hola ${owner1.name.split(' ')[0]}, soy ${
				agent && agent.analyst && agent.analyst.name
			}, ¿Tienes un amig@ que esté buscando una hipoteca? Invítale a nuestro Plan Amigobs y tanto tú como tu amigo podréis conseguir 50 € cada uno si firma su hipoteca 🏠 con una de las ofertas que le consigamos. ¡Y así hasta *500 €* 💶!%0a¿Cómo? Es muy fácil, envíale este link ${
				infoProfile.friendLink
			}. Además, seré yo quien le gestione su hipoteca 😉.%0a¡Invita ya y empieza a ganar!`;
		} else if (infoProfile && infoProfile.language === 'en') {
			textWs = `Hello ${owner1.name.split(' ')[0]}, I'm ${
				agent && agent.analyst && agent.analyst.name
			}. Do you have a friend who is looking for a mortgage? Invite him to our Amigobs Plan and both you and your friend can get €50 each if he signs his mortgage 🏠 with one of the offers we get for him. And so on up to *€500* 💶!%0aHow? It's very easy, send him this link ${
				infoProfile.friendLink
			}. In addition, I will be the one who manages his mortgage 😉.%0aInvite now and start winning!`;
		} else if (infoProfile && infoProfile.language === 'cat') {
			textWs = `Hola ${owner1.name} sóc ${
				agent && agent.analyst && agent.analyst.name
			},Tens un amic que està buscant una hipoteca? Invita'l al nostre Pla Amigobs i tant tu com el teu amic podreu aconseguir 50 € cadascun si ell signa la seva hipoteca 🏠 amb una de les ofertes que li aconseguim. I així fins a *500 €*  💶!%0aCom? És molt fàcil, envia-li aquest enllaç ${
				infoProfile.friendLink
			}. A més, seré jo qui es gestioni la seva (d'ell) hipoteca 😉.%0a Convida ja i comença a guanyar!`;
		} else {
			textWs = `Hola ${owner1.name} soy ${
				agent && agent.analyst && agent.analyst.name
			}, ¿Tienes un amig@ que esté buscando una hipoteca? Invítale a nuestro Plan Amigobs y tanto tú como tu amigo podréis conseguir 50 € cada uno si firma su hipoteca 🏠 con una de las ofertas que le consigamos. ¡Y así hasta *500 €* 💶!%0a¿Cómo? Es muy fácil, envíale este link ${
				infoProfile.friendLink
			}. Además, seré yo quien le gestione su hipoteca 😉.%0a¡Invita ya y empieza a ganar!`;
		}
		const linkWs = document.createElement('a');
		linkWs.href = `https://api.whatsapp.com/send?phone=${whatsappPhone}&text=${textWs}`;
		linkWs.target = '_blank';
		document.body.appendChild(linkWs);
		linkWs.click();
		createNote();
	};

	const createNote = () => {
		axios
			.post(
				`${env.api.url}/api/hadmin/mortgage-note`,
				{
					mortgageId: mortgageId,
					body: 'Whatsapp de amigobs enviado a cliente'
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				message.success('Su nota se ha creado correctamente.');
				getDataNotes();
			});
	};

	const changeContact = (a) => {
		if (a === 'morning') {
			return 'Mañanas';
		} else if (a === 'afternoon') {
			return 'Mediodias';
		} else if (a === 'evening') {
			return 'Tardes';
		}
	};

	const changeLang = (a) => {
		if (a === 'es' || a == 'es_ES') {
			return 'Español';
		} else if (a === 'en' || a === 'en_EN') {
			return 'Inglés';
		} else if (a === 'cat' || a == 'ca_ES') {
			return 'Catalan';
		}
	};

	const updateRiskText = (type, value) => {
		axios.post(
			`${env.api.url}/api/hadmin/risk-descriptions`,
			{
				mortgageId,
				type,
				value
			},
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);
	};

	const increase = () => {
		let percent = percent1 + 10;
		if (percent > 100) {
			percent = 100;
		}
		setPercent1(percent);
		updateRiskText('success-forecast', percent);
	};

	const decline = () => {
		let percent = percent1 - 10;
		if (percent > 100) {
			percent = 100;
		}
		setPercent1(percent);
		updateRiskText('success-forecast', percent);
	};

	const handleUpdateDate = (e) => {
		let key = 'closeDateEstimate';
		let participant = 'mortgage';
		let value = e;

		axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${mortgageId}`,
				{ key, participant, value },
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				message.success('Se actualizó exitosamente');
				dispatch(updateStateMortgages(!mortgageUpda));
			});
	};

	const handleDate = (date, dateString) => {
		if (dateString !== '') {
			let dateFormate = dayjs(date).format('YYYY-MM-DD');
			handleUpdateDate(dateFormate);
		}
	};

	const renderViability = () => {
		if (
			(dataMortgage.mortgage !== undefined &&
				profile.type !== 'risk' &&
				profile.type !== 'pre-risk') ||
			(dataMortgage.mortgage !== undefined && profile.email === 'jaime.aguirre@gibobs.com')
		) {
			const viabilityValue = dataMortgage.mortgage.viability;

			if (viabilityValue < 2) {
				return (
					<Text style={{ color: '#52C41A', fontSize: 12, fontWeight: 600 }}>
						{"'Viabilidad complicada'"}
					</Text>
				);
			} else if (viabilityValue < 3 && viabilityValue >= 2) {
				return (
					<Text style={{ color: '#52C41A', fontSize: 12, fontWeight: 600 }}>
						{'Viabilidad moderada'}
					</Text>
				);
			} else if (viabilityValue < 4 && viabilityValue >= 3) {
				return (
					<Text style={{ color: '#52C41A', fontSize: 12, fontWeight: 600 }}>
						{'Viabilidad muy saludable'}
					</Text>
				);
			} else if (viabilityValue >= 4) {
				return (
					<Text style={{ color: '#52C41A', fontSize: 12, fontWeight: 600 }}>
						{'Viabilidad excelente'}
					</Text>
				);
			}
		}
	};

	const renderViabilityProgress = () => {
		if (dataMortgage.mortgage !== undefined && dataMortgage.mortgage.viability !== undefined) {
			if (
				(profile.type !== 'risk' && profile.type !== 'pre-risk') ||
				profile.email === 'jaime.aguirre@gibobs.com'
			) {
				return (
					<Progress
						type="dashboard"
						strokeColor={'#52C41A'}
						trailColor={'#F1F7F8'}
						percent={((dataMortgage ? dataMortgage.mortgage.viability : null) * 100) / 5}
						format={() => (
							<div>
								<Text
									strong
									style={{
										fontSize: '10px',
										color: '#2F4858',
										marginBottom: '4px',
										display: 'grid'
									}}>
									{dataMortgage?.mortgage?.viability?.toFixed(1)}
								</Text>
							</div>
						)}
						width={28}
						height={28}
						className="EstimatedViabilityProgress"
						style={{
							textAlign: 'center',
							marginLeft: 32
						}}
					/>
				);
			}
		}
	};

	const sendRegister = (whatsappPhone) => {
		axios
			.post(
				`${env.api.url}/v1/hadmin/trays/create`,
				{ operationId: mortgage.id, type: 'whatsapp', client: whatsappPhone },
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {});
	};

	const renderApe = (owner1) => {
		if (owner1?.firstSurname !== undefined && owner1?.lastSurname !== undefined) {
			return owner1?.firstSurname + ' ' + owner1?.lastSurname;
		} else if (owner1?.firstSurname !== undefined && owner1?.lastSurname === undefined) {
			return owner1?.firstSurname;
		} else if (owner1?.firstSurname !== undefined && owner1?.lastSurname !== undefined) {
			return owner1?.lastSurname;
		} else {
			return '';
		}
	};

	const renderIdealista = (mortgage, owner1) => {
		if (mortgage.accountEmail === owner1.email) {
			<div
				style={{
					display: 'flex',
					width: '100%',
					placeContent: 'center'
				}}>
				<ShoppingCartOutlined style={{ marginRight: 15, color: '#FFFFFF', fontSize: 20 }} />{' '}
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '600',
						color: '#FFFFFF'
					}}>
					Personal Shopper
				</Text>
			</div>;
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@idealista.com')) {
			return (
				<div
					style={{
						display: 'flex',
						width: '100%',
						placeContent: 'center'
					}}>
					<img src={'/images/idealista.png'} alt="idealista" height={24} width={78} />
				</div>
			);
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@helloteca')) {
			return (
				<div
					style={{
						display: 'flex',
						width: '100%',
						placeContent: 'center'
					}}>
					<img src={'/images/helloteca.png'} alt="helloteca" height={24} width={78} />
				</div>
			);
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@housfy')) {
			return (
				<div
					style={{
						display: 'flex',
						width: '100%',
						placeContent: 'center'
					}}>
					<img src={'/images/housefy.png'} alt="housfy" height={24} width={78} />
				</div>
			);
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@iahorro')) {
			return (
				<div
					style={{
						display: 'flex',
						width: '100%',
						placeContent: 'center'
					}}>
					<img src={'/images/iahorro.png'} alt="iahorro" height={24} width={78} />
				</div>
			);
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@idealista')) {
			return (
				<div
					style={{
						display: 'flex',
						width: '100%',
						placeContent: 'center'
					}}>
					<img src={'/images/idealista.png'} alt="idealista" height={24} width={78} />
				</div>
			);
		} else if (
			dataMortgage?.extraData?.operation?.riskManager?.email.includes('@rastreator') ||
			dataMortgage?.extraData?.operation?.riskManager?.email.includes('@servicios.rastreator.com')
		) {
			return (
				<div
					style={{
						display: 'flex',
						width: '100%',
						placeContent: 'center'
					}}>
					<img src={'/images/rastreator.png'} alt="rastreator" height={24} width={78} />
				</div>
			);
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@trioteca')) {
			return (
				<div
					style={{
						display: 'flex',
						width: '100%',
						placeContent: 'center'
					}}>
					<img src={'/images/trioteca.png'} alt="trioteca" height={24} width={78} />
				</div>
			);
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@wypo')) {
			return (
				<div
					style={{
						display: 'flex',
						width: '100%',
						placeContent: 'center'
					}}>
					<img src={'/images/wypo.png'} alt="wypo" height={24} width={78} />
				</div>
			);
		} else {
			return undefined;
		}
	};
	const renderClassname = (dataMortgage) => {
		if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@idealista.com')) {
			return 'card-sabadell-idealista';
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@helloteca')) {
			return 'card-sabadell-helloteca';
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@housfy')) {
			return 'card-sabadell-housfy';
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@iahorro')) {
			return 'card-sabadell-iahorro';
		} else if (
			dataMortgage?.extraData?.operation?.riskManager?.email.includes('@idealista.gibobs.co')
		) {
			return 'card-sabadell-idealista';
		} else if (
			dataMortgage?.extraData?.operation?.riskManager?.email.includes('@rastreator') ||
			dataMortgage?.extraData?.operation?.riskManager?.email.includes('@servicios.rastreator.com')
		) {
			return 'card-sabadell-rastreator';
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@trioteca')) {
			return 'card-sabadell-trioteca';
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@wypo')) {
			return 'card-sabadell-wypo';
		} else {
			return 'cardPersonalShopper';
		}
	};

	const renderBorder = (dataMortgage) => {
		if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@idealista.com')) {
			return '1px solid #DDF164';
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@helloteca')) {
			return '1px solid #81C8D2';
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@housfy')) {
			return '1px solid #4AA2EC';
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@iahorro')) {
			return '1px solid #173259';
		} else if (
			dataMortgage?.extraData?.operation?.riskManager?.email.includes('@idealista.gibobs.co')
		) {
			return '1px solid #DDF164';
		} else if (
			dataMortgage?.extraData?.operation?.riskManager?.email.includes('@rastreator') ||
			dataMortgage?.extraData?.operation?.riskManager?.email.includes('@servicios.rastreator.com')
		) {
			return '1px solid #4397BD';
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@trioteca')) {
			return '1px solid #14213C';
		} else if (dataMortgage?.extraData?.operation?.riskManager?.email.includes('@wypo')) {
			return '1px solid #E75E50';
		} else {
			return '1px solid #C0DAEE';
		}
	};

	return (
		<>
			{dataMortgage && owner1 && (
				<Row style={{ marginTop: '10px' }} gutter={24}>
					<Col lg={8} md={12} xs={24}>
						<Card
							style={{
								border: '1px solid #C0DAEE',
								borderRadius: '4px',
								height: '100%'
							}}
							className={
								mortgage.clusterId === '6671b38e028a784f80063f918b'
									? 'card-challenge'
									: 'card-green-mortgage'
							}
							title={
								mortgage.subtype === 'green-mortgage' ? (
									<div
										style={{
											display: 'flex',
											width: '100%',
											placeContent: 'center',
											alignItems: 'center'
										}}>
										<HomeOutlined style={{ color: '#FFFFFF', fontSize: 20, marginRight: 15 }} />

										<Text
											style={{
												fontSize: '12px',
												fontWeight: '600',
												color: '#FFFFFF'
											}}>
											Hipoteca verde
										</Text>
									</div>
								) : mortgage.clusterId === '6671b38e028a784f80063f918b' ? (
									<div style={{ textAlign: 'center' }}>
										<Text style={{ color: '#FFFFFF' }}>Operación desafío 💪</Text>
									</div>
								) : undefined
							}>
							<div
								style={{
									display: 'flex',
									flexWrap: 'wrap',
									height: '100%',
									justifyContent: 'space-between'
								}}>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between'
									}}>
									<div>
										<div
											style={{
												marginBottom: '12px',
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												flexWrap: 'wrap'
											}}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '600',
													color: '#2F4858'
												}}>
												{owner1.name + ' ' + renderApe(owner1)}
											</Text>
										</div>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												marginBottom: mortgage.subtype === 'green-mortgage' && 28
											}}>
											{dataMortgage &&
												dataMortgage.nps === undefined &&
												(mortgage.stage === 'basic' ||
													mortgage.stage === 'full' ||
													mortgage.stage === 'documents' ||
													mortgage.stage === 'analysis') && (
													<div
														onClick={() => handleNPS()}
														style={{
															cursor: 'pointer',
															display: 'flex',
															alignItems: 'center',
															backgroundColor: '#02C3CD',
															width: 'auto',
															height: '32px',
															marginRight: '8px',
															borderRadius: '4px',
															paddingRight: 4
														}}>
														<MailOutlined
															style={{
																color: 'white',
																fontSize: '12px',
																margin: '4px'
															}}
														/>
														<Text
															style={{
																fontSize: '12px',
																fontWeight: '500',
																color: '#FFFFFF',
																width: 30
															}}>
															NPS
														</Text>
													</div>
												)}

											<div
												style={{
													cursor: 'pointer',
													backgroundColor: '#02C3CD',
													width: '32px',
													height: '32px',
													marginRight: '8px',
													borderRadius: '4px'
												}}>
												<a
													href={
														'https://api.whatsapp.com/send?phone=' + whatsappPhone

														// "&text=Estimado cliente, soy tu comercial de gibobs. Por favor haz click en el siguiente enlace para confirmar tu registro " + " y así podamos empezar a estudiar tu financiación."
													}
													onClick={() => sendRegister(whatsappPhone)}
													target="_blank"
													rel="noreferrer">
													<WhatsAppOutlined
														style={{
															color: 'white',
															fontSize: '20px',
															margin: '6px'
														}}
													/>
												</a>
											</div>

											<div>
												<div>
													<a
														style={{
															fontSize: '12px',
															fontWeight: '600',
															color: '#02C3CD'
														}}
														onClick={() => callClient(owner1.phone)}>
														{owner1.phone}
													</a>
												</div>
												<div>
													<Text
														style={{
															fontSize: '12px',
															fontWeight: 'normal',
															color: '#748EA0'
														}}>
														{owner1.email}
													</Text>
												</div>
											</div>
										</div>
									</div>

									<div>
										<div style={{ marginTop: 28 }}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}>
												Preferencia:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#02C3CD'
												}}>
												{infoProfile && changeContact(infoProfile.contactTime)}
											</Text>
										</div>
										<div>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}>
												Idioma:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#02C3CD'
												}}>
												{infoProfile && changeLang(infoProfile.language)}
											</Text>
										</div>
									</div>
								</div>
								<div
									style={{
										width: '120px',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',
										alignItems: 'flex-end'
									}}>
									{profile.type === 'admin' && (
										<a href={`/tools/blueprints/Operation/${mortgageId}`} target="_blank">
											<Button
												type="dashed"
												style={{ fontSize: 12, height: 25 }}
												className="button-secundary-gibobs">
												Ver en Blueprints
											</Button>
										</a>
									)}
									<Avatar
										size={48}
										icon={<UserOutlined />}
										src={
											infoProfile && infoProfile.avatar && infoProfile.avatar.data !== null ? (
												<Image
													preview={false}
													// style={{ cursor: "pointer" }}
													src={`data:image/png;base64,${infoProfile.avatar.data}`}
												/>
											) : (
												<Image />
											)
										}
									/>

									<div>
										<div
											style={{
												color: '#2F4858',
												fontSize: '10px',
												fontWeight: 500,
												marginBottom: '4px'
											}}>
											Otras operaciones
										</div>
										{otherOperations !== undefined && (
											<div>
												<div
													style={{
														color: '#02C3CD',
														fontSize: '10px',
														fontWeight: 500,
														cursor: 'pointer',
														textAlign: 'right'
													}}
													onClick={() => {
														navigate(`/operations/mortgages/${otherOperations[0].id}`);
														window.location.reload();
													}}>
													{otherOperations[0].name}
												</div>
												<Popover
													overlayClassName="gibobs-pop-over-resume"
													content={
														<div
															style={{
																display: 'flex',
																flexWrap: 'wrap',
																width: '182px',
																justifyContent: 'space-between'
															}}>
															{otherOperations !== undefined &&
																otherOperations.map((data, i) => {
																	if (i !== 0) {
																		return (
																			<div
																				style={{
																					color: '#02C3CD',
																					fontSize: '10px',
																					fontWeight: 500,
																					lineHeight: '12px',
																					margin: '4px',
																					cursor: 'pointer'
																				}}
																				onClick={() => {
																					navigate(`/operations/mortgages/${data.id}`);
																					setVisible(false);
																				}}>
																				{data.name}
																			</div>
																		);
																	}
																})}
														</div>
													}
													visible={visible}
													onVisibleChange={() => setVisible(!visible)}
													placement="bottom"
													trigger="click">
													<div style={{ float: 'right' }}>
														<PlusOutlined
															style={{
																color: '#02C3CD',
																fontSize: '11px',
																textAlign: 'right',
																cursor: 'pointer'
															}}
														/>
													</div>
												</Popover>
											</div>
										)}
									</div>
								</div>
							</div>
						</Card>
					</Col>
					<Col lg={8} md={12} xs={24}>
						<Card
							className={
								mortgage?.managementCode === 'sabadell-es' ? 'card-sabadell' : 'card-amigobs'
							}
							title={
								mortgage?.managementCode === 'sabadell-es' ? (
									<div
										style={{
											display: 'flex',
											width: '100%',
											placeContent: 'center'
										}}>
										<img src={'/images/sabadell.png'} alt="sabadell" height={15} />
									</div>
								) : mortgage.accountEmail !== owner1.email &&
								  mortgage?.managementCode !== 'sabadell-es' ? (
									<div
										style={{
											display: 'flex',
											width: '100%',
											placeContent: 'center',
											cursor: 'pointer'
										}}
										onClick={() => followUpWhatsapp(owner1.phone && owner1.phone)}>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '600',
												color: '#FFFFFF',
												marginRight: 11
											}}>
											Recuérdale el plan amigobs
										</Text>
										<ArrowRightOutlined
											style={{ marginRight: 15, color: '#FFFFFF', fontSize: 20 }}
										/>{' '}
									</div>
								) : undefined
							}
							style={{
								border:
									mortgage?.managementCode === 'sabadell-es'
										? '1px solid #006DFF'
										: '1px solid #C0DAEE',
								borderRadius: '4px',
								height: '100%'
							}}>
							<div style={{ display: 'flex', flexWrap: 'wrap', height: 'auto' }}>
								<div style={{ width: '80%' }}>
									<div>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#2F4858',
												marginRight: '4px'
											}}>
											Fecha de creación:
										</Text>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#02C3CD'
											}}>
											{moment(mortgage.createAt).format('DD-MM-YYYY')}
										</Text>
									</div>
									<div>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#2F4858',
												marginRight: '4px'
											}}>
											Fecha de actualización:
										</Text>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#02C3CD'
											}}>
											{moment(mortgage.updateAt).format('DD-MM-YYYY')}
										</Text>
									</div>
									<div style={{ marginTop: 18 }}>
										{renderViability()}

										{renderViabilityProgress()}
									</div>

									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											marginTop: '16px',
											marginBottom: '14px'
										}}>
										<div>
											<div>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: 'normal',
														color: '#2F4858'
													}}>
													Previsión de éxito
												</Text>
											</div>
											<div>
												<Progress
													style={{ width: '220px', marginRight: '10px' }}
													width={220}
													percent={percent1}
													strokeColor={'#02C3CD'}
												/>
												<MinusCircleOutlined
													style={{
														fontSize: '14px',
														color: '#02C3CD',
														marginRight: '6px'
													}}
													onClick={() => decline()}
												/>
												<PlusCircleOutlined
													style={{ fontSize: '14px', color: '#02C3CD' }}
													onClick={() => increase()}
												/>
											</div>
										</div>
									</div>
								</div>
								<div style={{ width: '20%' }}>
									{/* <Upload> */}
									<Avatar
										size={48}
										icon={<UserOutlined />}
										src={
											agent && agent.analyst && agent.analyst.avatar !== null ? (
												<Image
													preview={false}
													// style={{ cursor: "pointer" }}
													src={`${agent.analyst.avatar.link}`}
												/>
											) : (
												<Image src="https://gibobs-assets.s3.eu-central-1.amazonaws.com/assets-dev/6008ddb40164df16002f94c6b1_608a71a603b0c458502cd74a93?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIS4QMZRS5JUUI7VA%2F20210719%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20210719T064357Z&X-Amz-Expires=300&X-Amz-Signature=7cca0a6445382b1fb12bf1176fb7a3b3e205a9b852e61288ec043e42e51f25ef&X-Amz-SignedHeaders=host" />
											)
										}
									/>
									{/* </Upload> */}
									<div>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#2F4858'
											}}>
											Analista
										</Text>
									</div>
									<div style={{ width: '45px', textAlign: 'center' }}>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: 'normal',
												color: '#02C3CD'
											}}>
											{agent && agent.analyst && agent.analyst.name ? agent.analyst.name : null}
										</Text>
									</div>
								</div>
							</div>
							<Row style={{ display: 'flex' }}>
								<Col span={8}>
									<Text
										style={{
											fontSize: '12px',
											fontWeight: 'normal',
											color: '#2F4858',
											marginRight: '4px'
										}}>
										Fecha prevista de cierre:
									</Text>
								</Col>
								<Col span={10}>
									<Text
										style={{
											fontSize: '12px',
											fontWeight: '500',
											color: '#02C3CD'
										}}>
										<DatePicker
											className="pickerStyle"
											onChange={(date, dateString) => handleDate(date, dateString)}
											value={fechaFom && fechaFom !== 'Invalid Date' ? dayjs(fechaFom) : undefined}
											style={{ height: '14px', border: 'none', fontSize: '12px' }}
										/>
									</Text>
								</Col>
								<Col span={5}>
									{profile.email === 'fernando.lopez@gibobs.com' ||
									profile.email === 'jaime.aguirre@gibobs.com' ||
									profile.email === 'irene.masa@gibobs.com' ||
									profile.email === 'juan.benavides@gibobs.com' ||
									profile.email === 'silvia.varela@gibobs.com' ||
									profile.email === 'jose.hernandez@gibobs.com' ||
									profile.email === 'miriam.torrens@gibobs.com' ||
									profile.email === 'javier.canadilla@gibobs.com' ||
									profile.email === 'pablo.cabanyes@gibobs.com' ||
									profile.email === 'yanina.aguirre@gibobs.com' ||
									profile.email === 'pau.costa@gibobs.com' ||
									profile.email === 'alejandro.lois@gibobs.com' ||
									profile.email === 'christian.andres@gibobs.com' ||
									profile.email === 'jorge.lopez@gibobs.com' ||
									profile.email === 'jimmy.figueroa@gibobs.com' ||
									profile.email === 'samuel.ferrer@gibobs.com' ||
									profile.email === 'gonzalo.oliver@gibobs.com' ||
									profile.email === 'maria.ramirez@gibobs.com' ||
									profile.email === 'pablo.cotillas@gibobs.com' ||
									profile.email === 'mireia.martin@gibobs.com' ? (
										<Button
											type="primary"
											className="button-primary-danger-gibobs"
											onClick={() => {
												setShowModalReasign(true);
											}}
											style={{ float: 'left' }}>
											Reasignar
										</Button>
									) : (
										<div></div>
									)}
								</Col>
							</Row>
						</Card>
					</Col>
					<Col lg={8} md={12} xs={24}>
						<Card
							className={renderClassname(dataMortgage)}
							style={{
								border: renderBorder(dataMortgage),
								borderRadius: '4px',
								height: '100%'
							}}
							title={renderIdealista(mortgage, owner1)}>
							{(clientPreRegister && clientPreRegister.operationType !== 'web') ||
							(mortgage &&
								mortgage.accountEmail !== 'web@gibobs.com' &&
								clientPreRegister &&
								clientPreRegister.operationType === 'web') ? (
								<div style={{ display: 'flex', height: 'auto' }}>
									<div style={{ width: '76%' }}>
										<div>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#02C3CD'
												}}>
												{agentTypeName}
											</Text>
										</div>
										<div style={{ marginBottom: '16px' }}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}>
												Agencia:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#02C3CD'
												}}>
												{_.isNil(dataMortgage?.extraData?.operation?.riskManager)
													? mortgage.agencyName
													: mortgage.accountName}

												{mortgage && mortgage.operationAccountIsPremium && (
													<img
														src={'/images/premiumIcon.svg'}
														alt="premiunIcon"
														style={{ marginLeft: 6, marginTop: -3 }}
													/>
												)}
											</Text>
										</div>
										{dataMortgage?.extraData?.operation?.riskManager?.email.includes(
											'@idealista.com'
										) && (
											<div style={{ marginBottom: 15 }}>
												<Text style={{ fontSize: 12, fontWeight: 500, color: '#2F4858' }}>
													ID:{' '}
													<span style={{ fontWeight: 700 }}>
														{dataMortgage?.extraData?.operation?.externalId}
													</span>
												</Text>
											</div>
										)}
										<div style={{ marginBottom: '16px' }}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '600',
													color: '#2F4858'
												}}>
												{_.isNil(dataMortgage?.extraData?.operation?.riskManager)
													? mortgage.accountName
													: dataMortgage?.extraData?.operation?.riskManager?.name}
											</Text>
										</div>
									</div>
									{clientPreRegister && clientPreRegister.operationType !== 'web' ? (
										<div style={{ width: '24%', textAlign: 'center' }}>
											{/* <Upload> */}
											<Avatar
												size={48}
												icon={<UserOutlined />}
												src={
													agent && agent.agent && agent.agent.avatar !== null ? (
														<Image
															preview={false}
															// style={{ cursor: "pointer" }}
															src={`${agent.agent.avatar.link}`}
														/>
													) : (
														<Image />
													)
												}
											/>
											{/* </Upload> */}
											<div>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: '500',
														color: '#2F4858'
													}}>
													Comercial
												</Text>
											</div>
											<div style={{ textAlign: 'center' }}>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: 'normal',
														color: '#02C3CD'
													}}>
													{agent && agent.agent && agent.agent.name}
												</Text>
											</div>
										</div>
									) : null}
								</div>
							) : (
								<div style={{ width: '76%' }}>
									<div>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#02C3CD'
											}}>
											{agentTypeName}
										</Text>
									</div>
								</div>
							)}
							{(clientPreRegister && clientPreRegister.operationType !== 'web') ||
							(mortgage &&
								mortgage.accountEmail !== 'web@gibobs.com' &&
								clientPreRegister &&
								clientPreRegister.operationType === 'web') ? (
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div
										style={{
											backgroundColor:
												mortgage && Math.trunc(mortgage.accountPhone / 100000000) === 9
													? '#02C3CD'
													: '#E5E5E5',
											width: '32px',
											height: '32px',
											marginRight: '8px',
											borderRadius: '4px'
										}}>
										{mortgage && Math.trunc(mortgage.accountPhone / 100000000) === 9 ? (
											<a
												href={
													'https://api.whatsapp.com/send?phone=' + whatsappPhoneInmo

													// "&text=Estimado cliente, soy tu comercial de gibobs. Por favor haz click en el siguiente enlace para confirmar tu registro " + " y así podamos empezar a estudiar tu financiación."
												}
												target="_blank"
												rel="noreferrer"></a>
										) : null}
										<WhatsAppOutlined style={{ color: 'white', fontSize: '20px', margin: '6px' }} />
									</div>
									<div>
										<div>
											<a
												style={{
													fontSize: '12px',
													fontWeight: '600',
													color: '#02C3CD'
												}}
												onClick={() => callClient(mortgage.accountPhone)}>
												{_.isNil(dataMortgage?.extraData?.operation?.riskManager)
													? mortgage.accountPhone
													: dataMortgage?.extraData?.operation?.riskManager.phone}
											</a>
										</div>
										<div>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: 'normal',
													color: '#748EA0'
												}}>
												{_.isNil(dataMortgage?.extraData?.operation?.riskManager)
													? mortgage.accountEmail
													: dataMortgage?.extraData?.operation?.riskManager?.email}
											</Text>
										</div>
									</div>
								</div>
							) : null}

							{mortgage?.managementCode !== 'sabadell-es' && (
								<Row>
									<Col span={17}></Col>

									<Col span={5}>
										{profile &&
										(profile.email === 'israel.tena@gibobs.com' || profile.type === 'admin') ? (
											<Button
												type="primary"
												className="button-primary-danger-gibobs"
												onClick={() => {
													setShowModalReu(true);
												}}
												style={{ float: 'left' }}>
												Reubicar
											</Button>
										) : (
											<div></div>
										)}
									</Col>
								</Row>
							)}
						</Card>
					</Col>
				</Row>
			)}
		</>
	);
};

export default StatusWidgets;
